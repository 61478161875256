import AccidentPartIcon from '@mui/icons-material/Construction';
import AccidentPartList from './AccidentPartList';
import AccidentPartCreate from './AccidentPartCreate';
import AccidentPartEdit from './AccidentPartEdit';
import { GetManyParams } from 'react-admin';
import { ParseDataProvider } from '../data-provider';
import { Customer, ParsePointer } from '../types';



const resource = {
    list: AccidentPartList,
    create: AccidentPartCreate,
    edit: AccidentPartEdit,
    icon: AccidentPartIcon,
    recordRepresentation: "name"
};

export const accidentPartLifecycleCallbacks = {
    resource: "AccidentPart",
  
    afterRead: async (record: Customer, dataProvider: ParseDataProvider) => {
      return {
        ...record,
        author: record.author?.id,
      };
    },
  
    beforeSave: async (data: any, dataProvider: ParseDataProvider) => {
      return data;
    },
  
  };


export default resource;