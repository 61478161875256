import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateInput,
  FormDataConsumer,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  ValidationErrorMessage,
  Validator,
  maxValue,
  minLength,
  minValue,
  required,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { CreateEditProps } from "../interface";
import { Grid, Typography, Box, InputAdornment } from "@mui/material";
import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { CarInput } from "../shared/CarInput";
import { CustomerReferenceInput } from "../shared/CustomerReferenceInput";
import { RUBInputProps } from "../shared/RUBInput";
import DocsReferenceInput from "../shared/DocsReferenceInput";
import { DocTypeEnum } from "../types";
import { genderChoises } from "../shared/select";
import { CreateAccidentPart } from "../accident-part/CreateAccidentPart";
import { PhoneInput } from "../shared/PhoneInput";

const filterToQuery = (searchText: string) => ({ name: searchText });

export const AccidentCreateEdit = (props: CreateEditProps) => {
  const translate = useTranslate();

  return (
    <TabbedForm>
      <TabbedForm.Tab label="resources.Accident.tabs.identity">
        <Grid container width={{ xs: "100%", xl: "100%" }} spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.Accident.fieldGroups.identity")}
            </Typography>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <DateInput
                  source="date"
                  fullWidth
                  validate={required()}
                  defaultValue={new Date()}
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <CarInput source="car" isRequired />
              </Box>
            </Box>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={2} mr={{ xs: 0, sm: "0.5em" }}>
                <CustomerReferenceInput source="customer" isRequired />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <NumberInput
                  source="cost"
                  label="resources.Accident.fields.cost"
                  helperText={false}
                  InputProps={RUBInputProps}
                  fullWidth
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <NumberInput
                  source="mileage"
                  label="resources.Accident.fields.mileage"
                  fullWidth
                />
              </Box>
            </Box>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <ReferenceInput source="city" reference="City">
                  <SelectInput
                    optionText="name"
                    label={translate("resources.Accident.fields.city")}
                    fullWidth
                    validate={required()}
                  />
                </ReferenceInput>
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="place" fullWidth validate={required()} />
              </Box>
            </Box>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <NumberInput
                  source="numberOfParticipants"
                  fullWidth
                  step={1}
                  min={1}
                  defaultValue={2}
                />
              </Box>
              <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <TextInput source="article" fullWidth />
              </Box>
            </Box>
            <BooleanInput source="hasVictim" />
            <BooleanInput source="isCustomerCauser" fullWidth />
            <BooleanInput source="isPaid" fullWidth />
            <ReferenceArrayInput source="parts" reference="AccidentPart">
              <AutocompleteArrayInput
                label="resources.Accident.fields.damageList"
                filterToQuery={filterToQuery}
                optionText="name"
                optionValue="id"
                create={<CreateAccidentPart />}
              />
            </ReferenceArrayInput>
            <TextInput source="description" fullWidth multiline />
            <Box mt="2em" />
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      <TabbedForm.Tab label="resources.Accident.tabs.participants">
        <Grid container width={{ xs: "100%", xl: "100%" }} spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" gutterBottom>
              {translate("resources.Accident.fieldGroups.participants")}
            </Typography>
            <ArrayInput source="participants">
              <SimpleFormIterator
                fullWidth
                disableClear
                getItemLabel={(index) => `Участник ${index + 2}`}
              >
                <TextInput
                  source="lastname"
                  fullWidth
                  helperText={false}
                  validate={required()}
                  label="resources.Accident.fields.lastname"
                />
                <TextInput
                  source="firstname"
                  helperText={false}
                  fullWidth
                  validate={required()}
                  label="resources.Accident.fields.firstname"
                />
                <TextInput
                  source="surname"
                  fullWidth
                  helperText={false}
                  label="resources.Accident.fields.surname"
                />
                <BooleanInput source="isOwner" fullWidth helperText={false} label="resources.Accident.fields.isOwner" defaultValue={true}/>
                <PhoneInput
                  source="phone"
                  fullWidth
                  helperText={false}
                  label="resources.Accident.fields.phone"
                  format={(value: string) => value && value.replace(/\D/g, "")}
                />
                <SelectInput
                  source="gender"
                  fullWidth
                  helperText={false}
                  choices={genderChoises}
                  label="resources.Accident.fields.gender"
                />
                <TextInput
                  source="article"
                  fullWidth
                  helperText={false}
                  label="resources.Accident.fields.article"
                />
                <TextInput
                  source="drivingLicenseNum"
                  fullWidth
                  helperText={false}
                  label="resources.Accident.fields.drivingLicenseNum"
                />
                <NumberInput
                  source="mileage"
                  fullWidth
                  helperText={false}
                  label="resources.Accident.fields.mileage"
                />
                <TextInput
                  source="bodyNumber"
                  helperText={false}
                  fullWidth
                  label="resources.Accident.fields.bodyNumber"
                />
                <ReferenceInput
                  source="brand"
                  helperText={false}
                  reference="AutoBrand"
                  sort={{ field: "name", order: "ASC" }}
                >
                  <AutocompleteInput
                    optionText="name"
                    optionValue="id"
                    helperText={false}
                    filterToQuery={filterToQuery}
                    label="resources.Accident.fields.brand"
                  />
                </ReferenceInput>
                <FormDataConsumer>
                  {({
                    formData, // The whole form data
                    scopedFormData,
                    getSource, // The data for this item of the ArrayInput
                  }) => {
                    if (
                      !scopedFormData ||
                      !getSource ||
                      !scopedFormData.brand
                    ) {
                      return null;
                    }
                    return (
                      <ReferenceInput
                        source={getSource("model")}
                        reference="AutoModel"
                        filter={{ brand: scopedFormData.brand }}
                        sort={{ field: "name", order: "ASC" }}
                      >
                        <AutocompleteInput
                          optionText="name"
                          optionValue="id"
                          helperText={false}
                          validate={required()}
                          filterToQuery={filterToQuery}
                          label="resources.Accident.fields.model"
                        />
                      </ReferenceInput>
                    );
                  }}
                </FormDataConsumer>
                <TextInput
                  source="licensePlateNumber"
                  fullWidth
                  helperText={false}
                  label="resources.Accident.fields.licensePlateNumber"
                />
                <TextInput
                  source="legalAddress"
                  fullWidth
                  helperText={false}
                  label="resources.Accident.fields.legalAddress"
                />
                <BooleanInput
                  source="isSameAddress"
                  defaultValue={true}
                  fullWidth
                  helperText={false}
                  label="resources.Accident.fields.isSameAddress"
                />
                <FormDataConsumer>
                  {({
                    formData, // The whole form data
                    scopedFormData,
                    getSource, // The data for this item of the ArrayInput
                  }) => {
                    if (
                      !scopedFormData ||
                      !getSource ||
                      !scopedFormData.isSameAddress
                    ) {
                      return (
                        <TextInput
                          source="actualAddress"
                          fullWidth
                          helperText={false}
                          label="resources.Accident.fields.actualAddress"
                        />
                      );
                    }
                  }}
                </FormDataConsumer>
                <FormDataConsumer>
                  {({
                    formData, // The whole form data
                    scopedFormData,
                    getSource, // The data for this item of the ArrayInput
                  }) => {
                    if (
                      !scopedFormData ||
                      !getSource ||
                      !scopedFormData.isOwner
                    ) {
                      return (
                        <TextInput
                          source="carOwner"
                          fullWidth
                          helperText={false}
                          label="resources.Accident.fields.carOwner"
                        />
                      );
                    }
                    
                  }}
                </FormDataConsumer>

                <TextInput
                  source="insuranceNumber"
                  fullWidth
                  helperText={false}
                  label="resources.Accident.fields.insuranceNumber"
                />
                <TextInput
                  source="insuranceCompany"
                  fullWidth
                  helperText={false}
                  label="resources.Accident.fields.insuranceCompany"
                />
                <BooleanInput
                  source="isParticipantCause"
                  fullWidth
                  helperText={false}
                  label="resources.Accident.fields.isParticipantCause"
                />
              </SimpleFormIterator>
            </ArrayInput>
            <Box mt="2em" />
          </Grid>
        </Grid>
      </TabbedForm.Tab>
      {props.edit && (
        <TabbedForm.Tab label="resources.Accident.tabs.docs">
          <Grid container width={{ xs: "100%", xl: "100%" }} spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                {translate("resources.Accident.fieldGroups.docs")}
              </Typography>
              <DocsReferenceInput
                source={DocTypeEnum.ACCIDENT}
                target="accidentId"
              />
              <Box mt="2em" />
            </Grid>
          </Grid>
        </TabbedForm.Tab>
      )}
    </TabbedForm>
  );
};
