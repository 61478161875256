import { TranslationMessages } from "react-admin";
import russianMessages from "ra-language-russian/src";
import { A } from "node_modules/@fullcalendar/resource/internal-common";

const customRussianMessages: TranslationMessages = {
  ...russianMessages,
  pos: {
    search: "Поиск",
    configuration: "Конфигурация",
    language: "Язык",
    theme: {
      name: "Оформление",
      light: "Светлая",
      dark: "Темная",
    },
    dashboard: {
      new_customers: "Новые клиенты (с начала месяца)",
      total_cars: "Автомобили",
      total_free_cars: "Свободные авто",
      total_customers: "Клиенты",
      partners: "Партнеры",
      car_list: { title: "Список автомобилей" },
      today_orders: "Сегодня арендовано",
      today_bookings: "Сегодня забронировано",
      today_income: "Доход за сегодня",
      today_outcome: "Расход за сегодня",
      quick_actions: "Быстрые действия",
      create_booking: "Создать бронь",
      create_order: "Создать договор",
      create_customer: "Добавить клиента",
      notifications: {
        title: "Уведомления",
        mark_as_read: "Отметить как прочитанное",
      },
      order: {
        items:
          "by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items",
      },
      welcome: {
        title: "P&A Cars",
        subtitle: "CRM-система для управления автопарком и арендой автомобилей",
        ra_button: "Перейти на сайт",
      },
    },
    menu: {
      cars: "Автопарк",
      sales: "Продажи",
      customers: "Клиенты",
      orders: "Договора",
      admin: "Администрирование",
      my_queries: "Сохраненные запросы",
      maintenance: "Техническое обслуживание",
      profile: { title: "Профиль пользователя", edit: "Редактировать профиль" },
      company: { title: "Моя компания", edit: "Моя компания" },
      reports: { title: "Отчеты", edit: "Отчеты" },
    },
    reports: {
      cars: {
        insurance: { title: "Отчет по страхованию" },
        inspection: { title: "Отчет по ТО" },
      },
    },
    empty: {
      files: "Ни одного файла не загружено",
    },
  },
  resources: {
    AccidentPart: {
      name: "Наименование повреждения |||| Наименования повреждения",
      fields: {
        name: "Название",
      }
    },
    Accident: {
      name: "ДТП |||| ДТП",
      fields: {
        numStr: "Номер документа",
        date: "Дата ДТП",
        city: "Город",
        car: "Автомобиль",
        customer: "Клиент",
        description: "Описание",
        place: "Место ДТП",
        cost: "Сумма ущерба",
        author: "Автор",
        numberOfParticipants: "Кол-во участников",
        hasVictim: "Есть пострадавшие",
        article: "Статья нарушения ПДД",
        damageList: "Список повреждений",
        isCustomerCauser: "Является виновником",
        isParticipantCause: "Является виновником",
        firstname: "Имя",
        lastname: "Фамилия",
        surname: "Отчество",
        gender: "Пол",
        legalAddress: "Адрес прописки",
        actualAddress: "Адрес проживания",
        carOwner: "ФИО владельца авто",
        insuranceNumber: "Номер полиса",
        insuranceCompany: "Страховая компания",
        licensePlateNumber: "Гос. номер",
        brand: "Марка авто",
        model: "Модель авто",
        participants: "Данные участников",
        drivingLicenseNum: "Номер ВУ",
        mileage: "Пробег",
        bodyNumber: "Номер кузова",
        phone: "Телефон",
        isSameAddress: "Адрес проживания совпадает с пропиской",
        isPaid: "Оплачено",
        isOwner: "Является владельцем",
      },
      fieldGroups: {
        identity: "Основная информация",
        docs: "Прикрепленные файлы",
        participants: "Данные других участников",
        insurance: "Страховка",
      },
      tabs: {
        identity: "Основная информация",
        docs: "Прикрепленные файлы",
        participants: "Данные других участников",
      },
    },
    Act: {
      name: "Акт |||| Акты",
      fields: {
        numStr: "Номер документа",
        date: "Дата",
        customer: "Клиент",
        car: "Автомобиль",
        mileage: "Пробег",
        type: "Тип акта",
        description: "Дополнительное описание",
        author: "Автор",
        manager: "Менеджер",
        equipment: "Комплектация",
      },
      fieldGroups: {
        identity: "Основная информация",
        images: "Файлы осмотра",
        equipment: "Комплектация авто",
      },
      tabs: {
        identity: "Основная информация",
        files: "Файлы осмотра",
        equipment: "Комплектация",
      },
    },
    Profile: {
      fields: {
        username: "Номер телефона",
        firstname: "Имя",
        email: "Email",
        lastname: "Фамилия",
        password: "Пароль",
        passwordConfirm: "Подтверждение пароля",
        notificationsOptions: "Получать типы уведомлений",
      },
      fieldGroups: {
        identity: "Основная информация",
        password: "Изменение пароля",
        avatar: "Аватар",
        notifications: "Настройки уведомлений",
      },
      messages: {
        profile_updated_successful: "Ваш профиль успешно обновлен",
        profile_update_error: "Ошибка при обновлении профиля",
      },
    },
    City: {
      name: "Город |||| Города",
      fields: {
        name: "Название",
      },
    },
    Transaction: {
      name: "Движение денежных средств |||| Движения денежных средств",
      fields: {
        date: "Дата операции",
        customer: "Клиент",
        direction: "Тип (приход/расход)",
        type: "Тип операции",
        author: "Автор",
        amount: "Сумма",
        description: "Описание",
        account: "Касса/счет",
        numStr: "Номер документа",
      },
    },
    TransactionType: {
      name: "Тип транзакции |||| Типы транзакций",
      fields: {
        direction: "Тип (приход/расход)",
        name: "Название",
        author: "Автор",
      },
    },
    TransactionAccount: {
      name: "Касса/счет |||| Кассы/счета",
      fields: {
        name: "Название",
        author: "Автор",
      },
    },
    CarEquipment: {
      name: "Комплектация авто",
      fields: {
        createdAt: "Дата создания",
        date: "На дату",
        car: "Автомобиль",
        equipment: "Список комплектующих",
        author: "Автор",
      },
      fieldGroups: {
        identity: "Основная информация",
        photo: "Фото",
      },
    },
    CarEquipmentItem: {
      name: "Номенклатура комплектации авто",
      fields: {
        name: "Название",
        amount: "Количество",
        author: "Автор",
        condition: "Состояние",
      },
    },
    ExtraDoc: {
      name: "Доп. документ |||| Доп. документы",
      fields: {
        type: "Тип документа",
        serie: "Серия документа",
        number: "Номер документа",
        date: "Дата выдачи документа",
      },
    },
    Tariff: {
      name: "Тариф |||| Тарифы",
      fields: {
        name: "Название",
        carType: "Тип авто",
        data: "Тарифная сетка",
        overrun: "Стоимость перепробега (за 1 км)",
        deposit: "Залог",
        city: "Город",
        author: "Автор",
        daysFrom: "Дней от",
        price: "Цена",
        description: "Описание для договора",
      },
      fieldGroups: {
        identity: "Основная информация",
      },
    },
    PhotoCheck: {
      name: "Фотоосмотр |||| Фотоосмотры",
      fields: {
        createdAt: "Дата",
        images: "Фото",
        file: "Файл",
        side: "Сторона",
      },
    },
    _User: {
      name: "Пользователь |||| Пользователи",
      fields: {
        createdAt: "Дата регистрации",
        username: "Номер телефона",
        firstname: "Имя",
        email: "Email",
        lastname: "Фамилия",
        confirmed: "Номер подтвержден",
        banned: "Заблокирован",
        banReason: "Причина блокировки",
        roles: "Роли пользователя",
        position: "Должность",
        me: "Я",
      },
      tabs: {
        summary: "Общая информация",
        roles: "Роли пользователя",
      },
      errors: {
        password_mismatch: "Пароли не совпадают",
      },
      actions: {
        removeTitle: "Удаление доступа",
        removeConfirm:
          "Вы действительно хотите удалить доступ для этого сотрудника?",
        editUserRolesTitle: "Редактирование доступа сотрудника",
        addUserTitle: "Добавление нового сотрудника",
      },
    },
    _Role: {
      name: "Роль пользователя |||| Роли пользователя",
      fields: {
        title: "Название роли",
      },
    },
    AutoBrand: {
      name: "Марка авто |||| Марки авто",
      fields: {
        name: "Название марки",
      },
    },
    AutoModel: {
      name: "Модель авто |||| Модели авто",
      fields: {
        brand: "Марка авто",
        name: "Модель авто",
      },
    },
    Contact: {
      name: "Контакт |||| Контакты",
      fields: {
        type: "Тип",
        value: "Значение",
        description: "Описание",
        relationship: "Родственная связь",
      },
    },
    AccountInfo: {
      name: "Ревизиты организации",
      fields: {
        inn: "ИНН",
        kpp: "КПП",
        ogrn: "ОГРН",
      },
    },
    AdditionalService: {
      name: "Доп. услуга |||| Доп. услуги",
      fields: {
        name: "Наименование",
        type: "Тип услуги",
        price: "Стоимость",
        rewardType: "Тип вознаграждения",
        rewardAmount: "Вознаграждение",
        author: "Автор",
      },
    },
    Booking: {
      name: "Бронирование |||| Бронирования",
    },
    Order: {
      name: "Аренда |||| Договоры аренды",
      fields: {
        start: "Дата начала",
        end: "Дата окончания",
        car: "Автомобиль",
        customer: "Клиент",
        additionalData: "Дополнительные услуги",
        description: "Дополнительное описание",
        manager: "Менеджер",
        total: "Сумма итого",
        delivery: "Сумма подачи авто",
        deliveryAddress: "Адрес подачи авто",
        numStr: "Номер документа",
        paid: "Оплачено",
        source: "Источник",
        tariff: "Тариф",
        author: "Автор",
        closedAt: "Дата и время закрытия",
        type: "Тип договора",
        isClosed: "Договор закрыт",
        documentAsDeposit: "Документ вместо залога",
      },
      fieldGroups: {
        identity: "Основная информация",
        files: "Загруженные файлы",
        summary: "Стоимость услуг",
        payments: "Информация об оплате",
        pre_summary: "Предварительный расчет",
        final_summary: "Фактический расчет",
        acts: "Акты приема/передачи авто",
      },
      tabs: {
        identity: "Основная информация",
        payments: "Информация об оплате",
        acts: "Акты",
      },
      action: {
        closeOrder: "Закрыть договор",
      },
      notification: {
        close_success: "Договор успешно закрыт",
        close_error: "Ошибка при закрытии договора",
        doc_preview_error: "Ошибка при печати документа",
      },
    },
    BankInfo: {
      name: "Банковские реквизиты",
      fields: {
        bik: "БИК банка",
        bankName: "Название банка",
        bankCity: "Город",
        bankAccount: "Номер расчетного счета",
        korrAccount: "Номер корр. счета",
      },
    },
    CustomerSegment: {
      name: "Сегмент клиентов |||| Сегменты клиентов",
      fields: {
        name: "Название",
        author: "Автор",
      },
    },
    Customer: {
      name: "Клиент |||| Клиенты",
      amount: "Всего аренд",
      fields: {
        createdAt: "Дата регистрации",
        firstname: "Имя",
        lastname: "Фамилия",
        surname: "Отчетство",
        fullname: "ФИО",
        birthDate: "Дата рождения",
        birthPlace: "Место рождения",
        contacts: "Контактная информация",
        type: "Тип клиента",
        gender: "Пол",
        passportCountry: "Страна выдачи паспорта",
        passportSerie: "Серия",
        passportNum: "Номер",
        passportDepartment: "Кем выдан",
        passportDepartmentCode: "Код подразделения",
        passportDate: "Дата выдачи",
        passportDateUntil: "Дата действ. до",
        actualAddress: "Адрес проживания",
        legalAddress: "Адрес прописки",
        drivingLicenseNum: "Номер ВУ",
        sameAddresses: "Адрес совпадает с пропиской",
        drivingLicenseDate: "Дата выдачи",
        drivingLicenseDepartment: "Кем выдано",
        drivingLicenseDateUntil: "Срок действия",
        drivingExperience: "Стаж",
        phone: "Основной номер телефона",
        segments: "Сегменты клиента",
        images: "Фото",
        author: "Автор",
        allowedCategories: "Разрешенные категории",
        married: "Женат/Замужем",
        spouse: "Супруг(а)",
        criminalRecord: "Судимость",
        criminalRecordStr: "Судимость (описание)",
      },
      fieldGroups: {
        identity: "Основная информация",
        passport: "Паспортные данные",
        license: "Водительское удостоверение",
        photo: "Фото",
        files: "Загруженные файлы",
        extra: "Дополнительные документы",
        history: "История клиента",
      },
      tabs: {
        summary: "Общая информация",
        passport: "Паспортные данные",
        license: "Водительское удостоверение",
        files: "Загруженные файлы",
      },
      filters: {
        bookings: "Все бронирования",
        orders: "Все договора",
      },
    },
    Image: {
      name: "Фото |||| Фотографиии",
      fields: {
        title: "Название",
        file: "Файл",
        main: "Сделать главной",
        author: "Автор",
      },
      notification: {
        upload_error: "Ошибка при загрузке файлов",
      },
      actions: {
        uploadImages: "Загрузить фото",
      },
    },
    Doc: {
      name: "Документ |||| Документы",
      fields: {
        createdAt: "Дата загрузки",
        title: "Название",
        file: "Файл",
        author: "Автор",
      },
      notification: {
        upload_error: "Ошибка при загрузке файлов",
      },
      actions: {
        uploadDocuments: "Загрузить документ",
      },
    },
    Maintenance: {
      name: "Техническое обслуживание |||| Техническое обслуживание",
      fields: {
        start: "Дата начала работ",
        end: "Дата окончания работ",
        car: "Автомобиль",
        status: "Статус ТО",
        technician: "Мастер",
        amount: "Сумма ремонта",
        numStr: "Номер документа",
        description: "Доп. описание",
        data: "Список работ/запчастей",
        author: "Автор",
      },
      fieldGroups: {
        identity: "Основная информация",
        files: "Загруженные файлы",
      },
      tabs: {
        identity: "Основная информация",
        files: "Загруженные файлы",
      },
    },
    MaintenanceItem: {
      name: "Номенклатура ТО",
      fields: {
        name: "Наименование",
        type: "Тип",
        price: "Цен за ед. изм",
        unitType: "Ед. изм",
        sku: "OEM номер",
        date: "Дата",
        shop: "Источник",
        amount: "Кол-во",
        description: "Описание",
        author: "Автор",
        country: "Страна производства",
        manufacturer: "Производитель",
        body: "Кузов",
        model: "Модель ТС",
        brand: "Марка ТС",
      },
    },
    DocTemplate: {
      name: "Шаблон документа",
      fields: {
        name: "Наименование",
        file: "Файл",
        type: "Тип документа",
        isActive: "Активен",
        author: "Автор",
      },
    },
    Partner: {
      name: "Партнер |||| Партнеры",
      fields: {
        name: "Название",
        shortName: "Сокращенное наименование",
        fullName: "Полное наименование",
        positionOfHead: "Должность руководителя",
        positionReason: "На основании чего действует руководитель",
        lastnameAndInitials: "Фамилия и инициалы руководителя",
        legalAddress: "Юридический адрес",
        actualAddress: "Фактический адрес",
        city: "Город",
        contacts: "Контактная информация",
        type: "Тип",
        logo: "Логотип",
        value: "Значение",
        description: "Примечание",
        author: "Автор",
      },
      fieldGroups: {
        identity: "Основная информация",
        accountInfo: "Реквизиты организации",
        bankInfo: "Банковские реквизиты",
        addressInfo: "Адрес",
        contacts: "Контактная информация",
        files: "Загруженные файлы",
        users: "Список сотрудников",
        doc_templates: "Загруженные шаблоны документов",
      },
      tabs: {
        identity: "Основная информация",
        files: "Загруженные файлы",
        users: "Сотрудники",
        doc_templates: "Шаблоны документов",
      },
    },
    CarType: {
      name: "Тип авто |||| Типы авто",
      fields: {
        name: "Название типа",
        author: "Автор",
      },
    },
    PartnerCar: {
      name: "Автопарк |||| Автопарк",
      fields: {
        name: "Название авто",
        sortNum: "Поряд. номер",
        carType: "Тип",
        model: "Модель ТС",
        city: "Город",
        brand: "Марка ТС",
        technicalInspectionNumber: "Номер тех. осмотра",
        technicalInspectionUntil: "Срок действия тех. осмотра",
        licensePlateNumber: "Гос. номер",
        bodyType: "Тип кузова",
        bodyColor: "Цвет кузова",
        vehicleType: "Тип транспортного средства",
        tankCapacity: "Объем бака, л.",
        fuelRequirement: "Требования к топливу",
        osagoNumber: "Номер полиса ОСАГО",
        osagoStartDate: "ОСАГО Действ. с",
        osagoDate: "ОСАГО Действ. до",
        kaskoNumber: "Номер полиса КАСКО",
        kaskoStartDate: "КАСКО Действ. с",
        kaskoDate: "КАСКО Действ. до",
        fuelType: "Тип топлива",
        enginePower: "Мощность дивгателя, лс.",
        engineCapacity: "Объем двигателя, л.",
        engineNumber: "Номер двигателя",
        engineNumberFact: "Номер двигателя (замена)",
        bodyNumber: "Номер кузова",
        chassisNumber: "Номер шасси",
        vinNumber: "VIN номер",
        registrationDocPart: "Серия",
        registrationDocNumber: "Номер",
        registrationDocDepartment: "Кем выдано",
        registrationDocDate: "Когда выдано",
        passportType: "Тип ПТС",
        passportDate: "Когда выдан",
        passportPart: "Серия",
        passportDigitalDate: "Когда выдан",
        passportNumber: "Номер",
        passportDigitalNumber: "Номер (электрон)",
        passportDepartment: "Кем выдан",
        carDate: "Дата выпуска",
        wheelDrive: "Расположение руля",
        carOwner: "ФИО владельца",
        transmission: "Тип трансмиссии",
        drive: "Привод",
        osagoCompany: "Название страховой ОСАГО",
        kaskoCompany: "Название страховой КАСКО",
        registrationDate: "Дата регистрации",
        currentState: "Статус авто",
        carYear: "Год выпуска авто",
        price: "Стоимость авто",
        purchaseDate: "Дата покупки",
        images: "Фото автомобиля",
        hasTelemetry: "Установлена телеметрия",
        telemetryProviderName: "Поставщик телеметрии",
        telemetryPhoneNumber: "Номер симкарты",
        telemetryImei: "Номер IMEI",
        hasProtection: "Установлена сигнализация",
        protectionModel: "Модель сигнализации",
        author: "Автор",
        daysToOSAGOOut: "Дней до окончания ОСАГО",
        daysToKASKOOut: "Дней до окончания КАСКО",
        daysToTOOut: "Дней до окончания ТО",
        mileage: "Пробег",
      },
      fieldGroups: {
        identity: "Основная информация",
        extra: "Дополнительная информация",
        technicalInspection: "Тех. осмотр",
        osago: "ОСАГО",
        kasko: "КАСКО",
        registrationDoc: "Свидетельство о регистрации",
        passport: "Паспорт транспортного средства",
        images: "Фото",
        docs: "Загруженные документы",
        files: "Загруженные документы",
        telemetry: "Телеметрия",
        equipment: "Документы комплектации авто",
      },
      tabs: {
        summary: "Общая информация",
        docs: "Рег. документы",
        insurance: "Страхование",
        service: "История тех. обслуживания",
        files: "Дополнительные файлы",
        equipment: "Комплектация",
        accidents: "ДТП",
      },
      filters: {
        insurance_date: "Просроченные страховки",
        inspection_date: "Просроченные ТО",
        osago_out: "ОСАГО просрочен",
        kasko_out: "КАСКО просрочен",
        osago_will_out: "ОСАГО заканчивается",
        kasko_will_out: "КАСКО заканчивается",
        no_osago: "Нет ОСАГО",
        no_kasko: "Нет КАСКО",
        has_osago: "Есть ОСАГО",
        has_kasko: "Есть КАСКО",
        has_technical_inspection: "Есть ТО",
        no_technical_inspection: "Нет ТО",
        technical_inspection_will_out: "ТО заканчивается",
        technical_inspection_out: "ТО просрочен",
      },
    },
  },
  custom: {
    actions: {
      print: "Печать документа",
      docActions: "Действия с документом",
    },
    actType: {
      TRANSFER: "Акт передачи",
      INTERMEDIATE: "Промежуточный акт",
      FINAL: "Акт приемки",
    },
    auth: {
      phone: "Номер телефона",
    },
    carType: {
      ECONOM: "Эконом",
      BUSINESS: "Бизнес",
      PREMIUM: "Премиум",
    },
    bodyType: {
      SEDAN: "Седан",
      HATCHBACK: "Хэтчбек",
      STATION_WAGON: "Универсал",
      PICKUP_TRUCK: "Пикап",
      VAN: "Фургон",
      MINIVAN: "Минивэн",
      CABRIOLET: "Кабриолет",
      SUV: "Внедорожник",
      LIMOUSINE: "Лимузин",
      COUPE: "Купе",
      CROSSOVER: "Кроссовер",
    },
    vehicleType: {
      CAR: "Легковой",
      TRUCK: "Грузовой",
      SPECIAL: "Спец. техника",
    },
    fuelType: {
      GASOLINE: "Бензин",
      GAS: "Газ",
      DIESEL: "Дизель",
      HYBRID: "Гибрид",
      EV: "Розетка",
    },
    contactType: {
      PHONE: "Номер тел",
      EMAIL: "Email",
      WEBSITE: "Сайт",
      VK: "VK",
      TELEGRAM: "Telegram",
      TIKTOK: "TikTok",
      OK: "OK",
      INSTAGRAM: "Instagram",
      FACEBOOK: "Facebook",
    },
    customerType: {
      INDIVIDUAL: "Физ. лицо",
      COMPANY: "Юр. лицо",
    },
    extraDocType: {
      PASSPORT_SNG: "Паспорт СНГ",
      PASSPORT_WW: "Загран. паспорт",
      MILITARY_ID: "Военный билет",
      OTHER: "Другое",
    },
    gender: {
      MALE: "Мужской",
      FEMALE: "Женский",
    },
    additionalServiceType: {
      BY_DAY: "По дням",
      ONE_TIME: "Разовая",
      PERCENT: "Процент от начисления по аренде",
    },
    additionalServiceRewardType: {
      FIX: "Фиксированное",
      PERCENT: "Процент",
    },
    carPassportType: {
      PAPER: "Бумажный",
      DIGITAL: "Электронный",
    },
    unitType: {
      PIECE: "шт",
      KG: "кг",
      LITRE: "литр",
      HOUR: "час",
    },
    docTemplateType: {
      FROM_ORG_TO_ORG: "От организации к организации",
      FROM_ORG_TO_INDIVIDUAL: "От организации к физ. лицу",
      FROM_INDIVIDUAL_TO_ORG: "От физ.лица к организации",
      FROM_INDIVIDUAL_TO_INDIVIDUAL: "От физ.лица к физ. лицу",
      FROM_SELFEMPLOYED_TO_ORG: "От самозанятого к организации",
      FROM_SELFEMPLOYED_TO_INDIVIDUAL: "От самозанятого к физ. лицу",
      FROM_IP_TO_ORG: "От ИП к организации",
      FROM_IP_TO_INDIVIDUAL: "От ИП к физ. лицу",
      ACT_TRANSFER: "Акт передачи",
      ACT_INTERMEDIATE: "Промежуточный акт",
      ACT_FINAL: "Акт приемки",
    },
    wheelDrive: {
      RIGHT: "Правый",
      LEFT: "Левый",
    },
    carColor: {
      BLACK: "Черный",
      GREY: "Серый",
      SILVER: "Серебристый",
      WHITE: "Белый",
      DARK_BLUE: "Синий",
      BLUE: "Голубой",
      GREEN: "Зеленый",
      BURGUNDY: "Бордовый",
      RED: "Красный",
      ORANGE: "Оранжевый",
      PINK: "Розовый",
      BEIGE: "Бежевый",
      YELLOW: "Желтый",
      GOLDEN: "Золотистый",
      BROWN: "Коричневый",
      PURPLE: "Фиолетовый",
    },
    transmissionType: {
      AT: "Автомат",
      MT: "Механика",
      RT: "Робот",
    },
    carSide: {
      LEFT: "Слева",
      RIGHT: "Справа",
      FRONT: "Спереди",
      REAR: "Сзади",
      INSIDE: "Внутри",
      OTHER: "Другое",
    },
    driveType: {
      FWD: "Передний",
      RWD: "Задний",
      "4WD": "Полный",
    },
    partnerType: {
      COMPANY: "Юридическое лицо",
      INDIVIDUAL: "Физическое лицо",
      SELF_EMPLOYED: "Самозанятый",
      IP: "Индивидуальный предприниматель",
    },
    carStatus: {
      FREE: "Свободный",
      RENT: "В аренде",
      MAINTENANCE: "На тех. обслуживании",
      CHECK: "На осмотре",
      BOOKED: "Забронирован",
    },
    maintenanceStatus: {
      IN_PROGRESS: "В работе",
      DONE: "Завершено",
    },
    bookingSource: {
      MOBILE: "Приложение",
      OFFICE: "В офисе",
      PHONE: "Звонок",
      SITE: "Сайт",
    },
    maintenanceItemType: {
      SERVICE: "Работа",
      SPARE_PART: "Запчасть",
      MATERIAL: "Материал",
    },
    transactionDirection: {
      INCOME: "Приход",
      OUTCOME: "Расход",
    },
    carEventType: {
      RENT: "Договора аренды",
      BOOKED: "Бронирования",
      MAINTENANCE: "ТО",
    },
    userNotificationType: {
      INSURANCE_OSAGO: "Просроченная страховка ОСАГО",
      INSURANCE_KASKO: "Просроченная страховка КАСКО",
      MAINTENANCE: "Просроченное ТО",
      TECHNICAL_INSPECTION: "Просроченный тех. осмотр",
    },
    equipmentCondition: {
      GOOD: "Хорошее",
      NORMAL: "Среднее",
      BAD: "Плохое",
    },
    orderType: {
      ORDER: "Договор",
      BOOKING: "Бронирование",
    },
    relationship: {
      HUSBAND: "Муж",
      WIFE: "Жена",
      MOTHER: "Мама",
      SON: "Сын",
      DAUGHTER: "Дочь",
      DAD: "Папа",
      BROTHER: "Брат",
      SISTER: "Сестра",
      UNCLE: "Дядя",
      AUNT: "Тетя",
      GRANDFATHER: "Дедушка",
      GRANDMOTHER: "Бабушка",
      FRIEND: "Друг",
      GIRLFRIEND: "Подруга",
      FATHER_IN_LAW: "Тесть",
      MOTHER_IN_LAW: "Теща",
      FATHER_IN_LAW2: "Свекр",
      MOTHER_IN_LAW2: "Свекровь",
      COUSIN_BROTHER: "Двоюродный брат",
      COUSIN_SISTER: "Двоюродная сестра",
      DISTANT_RELATIVE: "Дальний родственник",
      OTHER: "Другое",
    },
    validationsMessages: {
      tariff: {
        daysFromOneRequired:
          "Тарифная сетка должна содержать хотя бы одну строку (от 1 дня)",
        uniqueDaysFrom: "Тарифная сетка не должна содержать одинаковые дни",
      },
      dates: {
        startBeforeEnd: "Дата начала должна быть раньше даты окончания",
      },
    },
  },
};

export default customRussianMessages;
