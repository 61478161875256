import {
  AutocompleteInput,
  Edit,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
  useTranslate,
} from "react-admin";
import { AutoBrand } from "src/types";

export const AutoModelEdit = () => (
  <Edit title={<AutoModelTitle />}>
    <SimpleForm>
      <ReferenceInput
        source="brand"
        reference="AutoBrand"
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteInput
          optionText="name"
          optionValue="id"
          validate={required()}
          filterToQuery={(searchText: string) => ({ name: searchText })}
        />
      </ReferenceInput>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

const AutoModelTitle = () => {
  const record = useRecordContext<AutoBrand>();
  const translate = useTranslate();
  return (
    <span>
      {translate("resources.AutoModel.name", { smart_count: 1 })}{" "}
      {record ? `"${record.name}"` : ""}
    </span>
  );
};

export default AutoModelEdit;
