import { useDefineAppLocation } from "@react-admin/ra-navigation/esm/src";
import { Create, SimpleForm, TextInput, required } from "react-admin";
import { CreateEdit } from "./createEdit";

export const AccidentPartCreate = () => {
  return (
    <Create redirect="list">
      <CreateEdit edit={false}/>
    </Create>
  );
};

export default AccidentPartCreate;
