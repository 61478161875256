import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

export const AutoModelCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <ReferenceInput
        source="brand"
        reference="AutoBrand"
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteInput
          optionText="name"
          optionValue="id"
          validate={required()}
          filterToQuery={(searchText: string) => ({ name: searchText })}
        />
      </ReferenceInput>
      <TextInput source="name" validate={[required()]} fullWidth />
    </SimpleForm>
  </Create>
);

export default AutoModelCreate;
